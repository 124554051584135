<script>
import { getContext } from "svelte";

let floor = getContext("floor_code");

</script>

<div class="navigation">    
    <div class="d-flex flex-wrap mt-4 p-2 inner">
        <div>
            <div class="floor-caption">動画を探す</div>
            <div class="d-flex me-2 mb-2 pt-2 floor-block">
                <a class="link-none-decolation" href="/videoa/"><div class="floor-menu floor-menu-left" class:floor-menu-current={floor == "videoa"}>AV</div></a>
                <a class="link-none-decolation" href="/videoc/"><div class="floor-menu" class:floor-menu-current={floor == "videoc"}>素人</div></a>
                <a class="link-none-decolation" href="/anime/"><div class="floor-menu floor-menu-right" class:floor-menu-current={floor == "anime"}>アニメ</div></a>
            </div>
        </div>
        <!--
        <div>
            <div class="floor-caption">本やCGを探す</div>
            <div class="d-flex me-2 mb-2 pt-2 floor-block">
                <div class="floor-menu-disabled floor-menu-left">本</div>
                <div class="floor-menu-disabled floor-menu-right">同人</div>                
            </div>
        </div>
        -->

        <div class="ms-auto">
            <div class="floor-caption">その他</div>
            <div class="d-flex me-2 mb-2 pt-2 floor-block">
                <a class="link-none-decolation" href="/blog/"><div class="floor-menu floor-menu-single">ブログ</div></a>
            </div>
        </div>
        
    </div>
    <div class="border-top mt-1"></div>
</div>

<style>
</style>