<script>
import Header from "./header.svelte";
import Navigation from "./navigation.svelte";
import Footer from "./footer.svelte";
import { localdb } from "../common/localdb.js";
import { getAllGenres } from "../common/fanza.js";
import { downloadPins, downloadCounts, db, MOBILE_MODE } from "../common/fanzafans.js";
import { collection, getDocs, query, where, limit, doc } from "firebase/firestore";

let videoaids = undefined;
let videocids = undefined;
let animeids = undefined;
let videoaGenres = undefined;
let videocGenres = undefined;
let animeGenres = undefined;
let videoaAllGenres = undefined;
let videocAllGenres = undefined;
let animeAllGenres = undefined;
let videoaSaleids = new Set();
let videocSaleids = new Set();
let animeSaleids = new Set();
let videoaWishlist = new Set();
let videocWishlist = new Set();
let animeWishlist = new Set();
let futures = [];
futures.push((async () => { videoaids = await downloadPins("videoa", "w", "rank.first") })());
futures.push((async () => { videocids = await downloadPins("videoc", "w", "rank.first") })());
futures.push((async () => { animeids = await downloadPins("anime", "w", "rank.first") })());
futures.push((async () => { videoaGenres = await downloadCounts("videoa", "genre")})());
futures.push((async () => { videocGenres = await downloadCounts("videoc", "genre")})());
futures.push((async () => { animeGenres = await downloadCounts("anime", "genre")})());
futures.push((async () => { videoaAllGenres = await getAllGenres(43)})());
futures.push((async () => { videocAllGenres = await getAllGenres(44)})());
futures.push((async () => { animeAllGenres = await getAllGenres(46)})());
futures.push((async () => { videoaSaleids = await downloadPins("videoa", "sale", "sale")})());
futures.push((async () => { videocSaleids = await downloadPins("videoc", "sale", "sale")})());
futures.push((async () => { animeSaleids = await downloadPins("anime", "sale", "sale")})());
futures.push((async () => { videoaWishlist = await loadWishList(43)})());
futures.push((async () => { videocWishlist = await loadWishList(44)})());
futures.push((async () => { animeWishlist = await loadWishList(46)})());
let videoaDocs = [];
let videocDocs = [];
let animeDocs = [];
let videoaGenreRanking = [];
let videoaGenreRankingForMobile = [];
let videocGenreRanking = [];
let videocGenreRankingForMobile = [];
let animeGenreRanking = [];
let animeGenreRankingForMobile = [];

function getRandomItems(arr, count) {
  const shuffled = arr.slice();
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];  // swap
  }
  return shuffled.slice(0, count);
}

Promise.all(futures).then(() => {
    let genreCompare = (lhs, rhs) => { return rhs[1] - lhs[1]; };
    videoaGenres = Object.entries(videoaGenres).sort(genreCompare).slice(0, 40);
    videocGenres = Object.entries(videocGenres).sort(genreCompare).slice(0, 40);
    animeGenres = Object.entries(animeGenres).sort(genreCompare).slice(0, 40);

    let forRanking = (genres, allGenres) => 
    {
        let ret = [];
        for(let genre of genres)
            ret.push(allGenres.find(val => genre[0] == val.genre_id));
        return ret;
    };

    videoaGenreRanking = forRanking(videoaGenres, videoaAllGenres);
    videocGenreRanking = forRanking(videocGenres, videocAllGenres);
    animeGenreRanking = forRanking(animeGenres, animeAllGenres);
    videoaGenreRankingForMobile = getRandomItems(videoaGenreRanking, 10);
    videocGenreRankingForMobile = getRandomItems(videocGenreRanking, 10);
    animeGenreRankingForMobile = getRandomItems(animeGenreRanking, 10);

    let proms = [];
    proms.push((async () => { videoaDocs = await updateDocs(43, videoaids); })());
    proms.push((async () => { videocDocs = await updateDocs(44, videocids); })());
    proms.push((async () => { animeDocs = await updateDocs(46, animeids); })());
    Promise.all(proms);
});

async function getExcludeIds(floorCode)
{
    
    if(floorCode != "anime")
    {
        let proms = [];
        let skatolo = undefined;
        let dappun = undefined;
        let kancho = undefined;
        let gey = undefined;
        proms.push((async () => { skatolo = await downloadPins(floorCode, "genre", "4018")})());
        proms.push((async () => { dappun = await downloadPins(floorCode, "genre", "5013")})());        
        proms.push((async () => { kancho = await downloadPins(floorCode, "genre", "5014")})());
        if(floorCode == "videoa")
            proms.push((async () => { gey = await downloadPins(floorCode, "genre", "4060")})());

    
        if(proms.length > 0)
        {
            await Promise.all(proms);
            
            let excludeIds = new Set();
            if(floorCode != "anime")
            {
                excludeIds = new Set([...skatolo, ...dappun, ...kancho]);
                if(floorCode == "videoa")
                    excludeIds = new Set([...excludeIds, ...gey]);
            }            
            return excludeIds;            
        }
    }

    return new Set();
}

async function updateDocs(floorId, ids)
{
    let idarray = [...ids];

    let excludeIds = undefined;
    if(floorId == 43)
        excludeIds = await getExcludeIds("videoa");
    else if(floorId == 44)
        excludeIds = await getExcludeIds("videoc");
    else if(floorId == 46)
        excludeIds = await getExcludeIds("anime");
    else
        excludeIds = new Set();

    idarray = idarray.filter(val => !excludeIds.has(val));

    let inputs = MOBILE_MODE ?  [idarray.slice(0, 10)/*, idarray.slice(10, 20)*/] :
                                [idarray.slice(0, 10), idarray.slice(10, 20), idarray.slice(20, 30)];

    function requestData(pin_ids)
    {
        let colref = collection(db, "floors", String(floorId), "items");
        let q = query(colref, limit(10), where("pin_id", "in", pin_ids));
        return getDocs(q); 
    }

    let proms = [];
    for(let input of inputs)
        proms.push(requestData(input));

    let resultDocs = [];
    let results = await Promise.all(proms); 
    results.forEach(docs => {
        docs.forEach(doc => {
            let data = doc.data();
            let uhd = doc.get("prices.deliveries.4k");
            if(uhd)
                data.prices.deliveries.uhd = uhd;
            
            resultDocs.push(data);
        });
    });

    return resultDocs.sort((lhs,rhs) => { return rhs["unique_rank"] - lhs["unique_rank"]; });
}

async function loadWishList(floorId)
{
    try
    {
        let storeVal = await localdb.params.get(`wishlist${floorId}`);
        if(storeVal)
        {
            return storeVal.wishlist;
        }

        return new Set();
    }catch(e)
    {
        console.log(`エラー：wishlist[${floorId}]の読み込みに失敗しました。 ${e}`);
        return new Set();
    }
}
</script>

<div class="contents">

    <header>
        <Header />
        <Navigation />
    </header>

    <main class="mt-4">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="mb-4">
                        <h1 class="fs-normal">より快適なエロライフを</h1>
                        <div class="fs-small">
                            FANZAのファンによる、FANZAの不満点を解消した、高速高性能エロコンテンツ検索サイト。条件を保存しておくことができるので使い込めば使い込むほど自分に合った結果を取得できます。<br>
                        </div>
                    </div>
                
                    <div class="mb-5">
                        <div class="mo-menu mb-3"><span class="bi-arrow-right-square">&nbsp;&nbsp;AVを探す</span></div>
                        <div class="d-flex mb-3 align-items-center">
                            <a class="link-none-decolation" href="/videoa/"><div class="search"><span class="bi-search">&nbsp;</span>詳しく探す</div></a>
                            <div class="ms-3">
                                <div class="fs-085"><a href="/videoa/?sale=true">セール中</a>の商品 <b>{videoaSaleids.size}</b>件</div>
                                <div class="fs-085">お気に入りに登録中 <b>{videoaWishlist.size}</b>件</div>
                            </div>
                        </div>
                        <div class="fs-085 fs-bold">最近評価の高い作品</div>
                        <div class="d-flex justify-content-center mb-3">
                            <div class="d-flex flex-wrap">
                            {#each videoaDocs as doc}
                                <a href={`/videoa/item.html?id=${doc["pin_id"]}`} class="link-none-decolation"><img src={doc["imageURL"]["small"]} alt={doc["title"]} class="p-1 mo-image" /></a>
                            {/each}
                            </div>
                        </div>
                        <div class="fs-085 fs-bold mb-2">人気のジャンル</div>
                        <div class="fs-085">
                            {#if MOBILE_MODE}
                                {#each videoaGenreRankingForMobile as genre}
                                <div class="d-inline-block m-1"><a class="genre" href={`/videoa/?genre=${genre.genre_id}`}>{genre.name}</a></div>/
                                {/each}
                            {:else}
                                {#each videoaGenreRanking as genre}
                                <a class="genre" href={`/videoa/?genre=${genre.genre_id}`}>{genre.name}</a>&nbsp;&nbsp;&nbsp;
                                {/each}
                            {/if}
                        </div>
                    </div>
                    <div class="mb-5 hint">
                        <div class="bi-info-circle fs-bold">&nbsp;ヒント</div>
                        <div>
                            除外機能を上手く使うことによって、自分が欲しい作品がより探しやすくなります。検索結果にでてくるジャンル、女優名、メーカーといったもののの後ろには×（バツマーク）がついています。このバツマークをタップすると、関係するものが一切検索結果に出てこなくなります。                            
                        </div>
                    </div>
                    <div class="mb-5">
                        <div class="mo-menu mb-3"><span class="bi-arrow-right-square">&nbsp;&nbsp;素人動画を探す</span></div>
                        <div class="d-flex mb-3 align-items-center">
                            <a class="link-none-decolation" href="/videoc/"><div class="search"><span class="bi-search">&nbsp;</span>詳しく探す</div></a>
                            <div class="ms-3">
                                <div class="fs-085"><a href="/videoc/?sale=true">セール中</a>の商品 <b>{videocSaleids.size}</b>件</div>
                                <div class="fs-085">お気に入りに登録中 <b>{videocWishlist.size}</b>件</div>
                            </div>
                        </div>
                        <div class="fs-085 fs-bold">最近評価の高い作品</div>
                        <div class="d-flex justify-content-center mb-3">
                            <div class="d-flex flex-wrap">
                            {#each videocDocs as doc}
                                <a href={`/videoc/item.html?id=${doc["pin_id"]}`} class="link-none-decolation"><img src={doc["imageURL"]["small"]} alt={doc["title"]} class="p-1 mo-image" /></a>
                            {/each}
                            </div>
                        </div>
                        <div class="fs-085 fs-bold mb-2">人気のジャンル</div>
                        <div class="fs-085">
                            {#if MOBILE_MODE}
                                {#each videocGenreRankingForMobile as genre}
                                <div class="d-inline-block m-1"><a class="genre" href={`/videoc/?genre=${genre.genre_id}`}>{genre.name}</a></div>/
                                {/each}
                            {:else}
                                {#each videocGenreRanking as genre}
                                <a class="genre" href={`/videoc/?genre=${genre.genre_id}`}>{genre.name}</a>&nbsp;&nbsp;&nbsp;
                                {/each}
                            {/if}
                        </div>
                    </div>

                    {#if MOBILE_MODE}
                    <div class="mb-5 hint">
                        <div class="bi-info-circle fs-bold">&nbsp;ヒント</div>
                        <div>
                            <a href="/videoa/">詳細検索ページ</a>の画面下部にある「検索条件の変更」をタップすると、より詳細な検索条件を設定できます。除外や絞り込みのON/OFFに加え、配信形式や配信開始年、セール中のもののみ表示といったことが簡単に切り替えられます。検索条件の保存や、保存した条件のロードもここからできるので、これらを保存しておけば次回以降もすぐに設定どおりの検索を行うことができます。
                        </div>
                    </div>
                    {/if}

                    <div class="mb-5">
                        <div class="mo-menu mb-3"><span class="bi-arrow-right-square">&nbsp;&nbsp;エロアニメを探す</span></div>
                        <div class="d-flex mb-3 align-items-center">
                            <a class="link-none-decolation" href="/anime/"><div class="search"><span class="bi-search">&nbsp;</span>詳しく探す</div></a>
                            <div class="ms-3">
                                <div class="fs-085"><a href="/anime/?sale=true">セール中</a>の商品 <b>{animeSaleids.size}</b>件</div>
                                <div class="fs-085">お気に入りに登録中 <b>{animeWishlist.size}</b>件</div>
                            </div>
                        </div>
                        <div class="fs-085 fs-bold">最近評価の高い作品</div>
                        <div class="d-flex justify-content-center mb-3">
                            <div class="d-flex flex-wrap">
                            {#each animeDocs as doc}
                                <a href={`/anime/item.html?id=${doc["pin_id"]}`} class="link-none-decolation"><img src={doc["imageURL"]["small"]} alt={doc["title"]} class="p-1 mo-image" /></a>
                            {/each}
                            </div>
                        </div>
                        <div class="fs-085 fs-bold mb-2">人気のジャンル</div>
                        <div class="fs-085">
                            {#if MOBILE_MODE}
                                {#each animeGenreRankingForMobile as genre}
                                <div class="d-inline-block m-1"><a class="genre" href={`/anime/?genre=${genre.genre_id}`}>{genre.name}</a></div>/
                                {/each}
                            {:else}
                                {#each animeGenreRanking as genre}
                                <a class="genre" href={`/anime/?genre=${genre.genre_id}`}>{genre.name}</a>&nbsp;&nbsp;&nbsp;
                                {/each}
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

</div>

<Footer />

<style>

.search {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top: 1px solid var(--theme-color) !important;
    border-bottom: 1px solid var(--theme-color) !important;
    border-right: 1px solid var(--theme-color) !important;
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
    border: 1px solid var(--theme-color) !important;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1rem;
    color: var(--theme-color);
    background-color: #fff6f8;
    padding: 1rem 2rem;
}

.search:hover {
    background-color: #f0f2ff;
}

.mo-menu {
    font-size: 1rem;
    font-weight: bold;
    background-color: var(--theme-color);
    color: white;
    padding: 0.3rem 0.5rem;
}

.mo-image {
    width: 110px;
}

.genre
{
    text-decoration: none;
}

.hint
{
    border: 1px solid #999;
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
    background-color: #eee;
    padding: 0.5rem;
    font-size: 0.85rem;
}

</style>