// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { writable } from "svelte/store";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCo8bzXwheCZrVDKv46PRKWRJkcHqiKm-w",
  authDomain: "fanzafans.firebaseapp.com",
  projectId: "fanzafans",
  storageBucket: "fanzafans.appspot.com",
  messagingSenderId: "601848963868",
  appId: "1:601848963868:web:d7084aaabdfc34c2af10d9",
  measurementId: "G-8ZCTG9Q3ZX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();

const LITTLE_ENDIAN = new Uint8Array(Uint16Array.of(1).buffer)[0] === 1;

export const START_YEAR = 2002;
export const YEAR_RANGE = new Date().getFullYear() - START_YEAR + 1;

export const SMALL_WINDOW_WIDTH = 1000;

export const MOBILE_MODE = window.screen.width < SMALL_WINDOW_WIDTH || window.screen.height < SMALL_WINDOW_WIDTH;
export const DISPLAY_MIN_LENGTH = window.screen.width < window.screen.height ? window.screen.width : window.screen.height;

export async function downloadPins(floorCode, category, name)
{
    const res = await fetch(`https://storage.googleapis.com/fanzafans/${floorCode}/ids/${category}/${name}`);
    if(!res.ok)
        return new Set();

    const buffer = new Uint8Array(await res.arrayBuffer());
    let pins = [];
    for(let i=0; i<buffer.byteLength; i+=3)
    {
        let pin = 0
        if(LITTLE_ENDIAN)
            pin = (buffer[i+2] << 16) | (buffer[i+1] << 8) | (buffer[i]);
        else
            pin = (buffer[i] << 16) | (buffer[i+1] << 8) | (buffer[i+2]);
        pins.push(pin);
    }
    return new Set(pins);
}

export async function downloadCounts(floorCode, category)
{
    const res = await fetch(`https://storage.googleapis.com/fanzafans/${floorCode}/ids/${category}/count.json`);
    if(!res.ok)
        return null;

    return res.json();
}

export async function getAllMakers(floorCode)
{
    const res = await fetch(`https://storage.googleapis.com/fanzafans/${floorCode}/ids/maker/all.json`);
    const json = await res.json();
    return json;
}

export async function findMaker(floorCode, makerId)
{
    let makers = await getAllMakers(floorCode);
    for(let maker of makers)
    {
        if(maker.maker_id == makerId)
            return maker;
    }

    return null;
}

export async function downloadDeliveryTypePins(deliveryConditions, floorCode)
{
    if(deliveryConditions.delivery.type != "default")
    {
        return await downloadPins(floorCode, "delivery", deliveryConditions.delivery.type);
    }

    return undefined;
}

export async function downloadDeliveryPricePins(deliveryConditions, floorCode)
{
    if(deliveryConditions.price.type != "default")
    {
        let type = deliveryConditions.delivery.type != "default" ? deliveryConditions.delivery.type : "download";
        return await downloadPins(floorCode, `delivery_price/${type}`, deliveryConditions.price.type);
    }

    return undefined;
}

export async function downloadDeliveryYearsPins(deliveryConditions, floorCode)
{
    let begin = START_YEAR;
    let end = begin + YEAR_RANGE - 1;

    if(deliveryConditions.year.low == "default" && deliveryConditions.year.high == "default")
    {
        return undefined;
    }
    else
    {
        if(deliveryConditions.year.low != "default")
            begin = deliveryConditions.year.low;
        if(deliveryConditions.year.high != "default")
            end = deliveryConditions.year.high;

        let proms = [];
        for(let year=begin; year<=end; year++)
            proms.push(downloadPins(floorCode, "year", year));            

        let idslist = await Promise.all(proms);
        let idset = new Set([].concat(...idslist.map(v => [...v])));
        
        return idset;
    }
}

export function takeOne(results)
{
    let buf = [];
    results.forEach(doc => {
        buf.push(doc);
    });

    if(buf.length > 0)
        return buf[0];

    return null;
}

export function difference(src, ...pinslist)
{
    if(pinslist.length == 0)
        return new Set(src);
    
    let ret = new Set(src);
    for(let pins of pinslist)
    {
        for(let pin of pins)
            ret.delete(pin);
    }

    return ret;
}

export function intersection(...pinslist)
{
    if(pinslist.length == 0)
        return null;

    if(pinslist.length == 1)
        return new Set(pinslist[0]);

    let current = pinslist[0];
    for(let i=1; i<pinslist.length; i++)
    {   
        let pins = pinslist[i];
        let result = new Set();
        for(let val of current)
        {
            if(pins.has(val))
                result.add(val);
        }
        current = result;
    }

    return current;
}