<script>
</script>

<footer class="bg-theme text-theme footer">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="me-4">Copyright © FANZAFANS.</div>
        <a href="https://affiliate.dmm.com/api/"><img src="https://p.dmm.co.jp/p/affiliate/web_service/r18_88_35.gif" width="88" height="35" alt="WEB SERVICE BY FANZA" /></a>
    </div>
</footer>

<style>

</style>