
let urlBase = "https://api.dmm.com/affiliate/v3/"
let affiliateId = "fanzafansjp-999"
let apiId = "fwtsd9frbPugVkKrL9QU"

function getBasicParams()
{
    return { api_id: apiId, affiliate_id: affiliateId, output : "json" };
}

function createParams(params)
{
    return Object.entries(params).map((e) => {
        let key = e[0];
        let value = encodeURI(e[1]);
        return `${key}=${value}`;
    }).join("&");
}

function createURL(apiname, params)
{
    return `${urlBase + apiname}?${createParams(params)}`;
}

export async function getAllGenres(floorId)
{
    const MAX_COUNT = 1000; //最大取得数（無限ループを作らないため)
    const HITS = 500;

    let genres = [];
    let params = getBasicParams();
    params["floor_id"] = floorId;
    params["hits"] = HITS;
    for(let offset=1; offset<MAX_COUNT; offset+=HITS)
    {
        params["offset"] = offset;
        params["HITS"] = HITS;
        let json = await (await fetch(createURL("GenreSearch", params))).json();
        let result = json["result"];
        if(result["status"] != 200)
        {
            console.log("Failed to retrieve genre from fanza.");
            break;
        }

        genres = genres.concat(result["genre"]);
        if(result["result_count"] < HITS)
            break;
    }

    return genres;
}

export async function findGenre(floorId, genreId)
{
    let genres = await getAllGenres(floorId);
    for(let g of genres)
    {
        if(g.genre_id == genreId)
            return g;
    }

    return null;
}

export async function searchActress(params)
{

    let json = await (await fetch(createURL("ActressSearch", {...params, ...getBasicParams()}))).json();
    let result = json["result"];
    result["total_count"] = Number(result["total_count"]);

    return result;
}

export async function findActress(id)
{
    let params = { actress_id : id };
    let result = await searchActress(params);
    if(result.actress)
        return [...result.actress][0];

    return null;
}