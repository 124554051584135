<script>
import { MOBILE_MODE } from "../common/fanzafans.js";
</script>

<div class="bg-theme text-theme" class:sticky-top={!MOBILE_MODE}>
    <div class="d-flex align-items-center">
        <a class="brand" href="/">FANZAFANS</a>
        <small class="fs-small me-auto">FANZAをもっと便利に使うサービス</small>
    </div>
</div>

<style>

</style>